/**********************************
  GLOBAL LAYOUT
**********************************/
.expanded-tracker-page {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    margin: 0; 
    padding: 0;
    font-family: 'Syne', sans-serif;
    align-items: flex-start; 
}

.main-content {
    padding-left: 100px;
    display: flex;
    flex: 1;
    overflow: auto;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.graph-container {
    flex: 1;
    height: 60vh;
    margin-bottom: 2rem;
}

.dashboard-container h1 {
    text-align: center;
}

/**********TIME BUTTONS*********/

.time-range-container {
    display: flex;               
    gap: 8px;                    
    justify-content: center;     
    align-items: center;         
    margin: 16px 0;             
}
  
.time-range-button {
    background-color: #f0f0f0;   
    border: none;                
    border-radius: 9999px;       
    padding: 8px 16px;           
    font-size: 1rem;             
    cursor: pointer;             
    transition: background-color 0.2s ease, color 0.2s ease;
}
  
.time-range-button:hover {
    background-color: #e2e2e2;   
}
  
.time-range-button.selected {
    background-color: #007BFF;   
    color: #fff;                 
    font-weight: bold;           
}

/****************** insights container ************************/

.insights-container {
    margin: 0 auto;   
    border-radius: 8px;
    padding: 16px;
    width: 350px;
}
  
  /* Title styling */
.insights-container h1 {
    margin-top: 0;       
    margin-bottom: 11%;  
    font-size: 1.5rem;
    text-align: center; 
}
  
.insights-tab-data {
    display: flex;      
    flex-direction: column; 
    gap: 16px;              
}
  

.insights-flex-container {
    display: flex;
    flex-direction: column;
    gap: 25px; 
}
  

.insight-item {
    display: flex;
    flex-direction: column;
    align-items: center;      
    background-color: #fff;   
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
}
  
.insight-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
}
  
.insight-value {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0; 
    margin-bottom: 4px;
}
  
.insight-label {
    font-size: 0.9rem;
    color: #555;
    margin: 0;
    margin-bottom: 8px; 
}
  
.insights-tab-data p {
    margin: 0;
    padding: 8px 0;
    text-align: center;
}






/*********************************/
/* MOBILE STYLING ***************/
/********************************/


@media (max-width: 768px) {
    .expanded-tracker-page {
        flex-direction: column;
        height: auto;
    }

    .main-content {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        width: 100%;
        overflow: visible;
    }

    .dashboard-container {
        width: 100%;
        margin-bottom: 1rem;
    }

    .graph-container {
        width: 100%;
        height: auto;
    }

    .insights-container {
        margin: 0 auto 2rem;
    }

    
}