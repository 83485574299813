.dashboard-side-menu {
    position: fixed;      
    top: 0;
    left: 0;
    bottom: 0;
    width: 80px;            
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 16px 0;        
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 10;            
  }
  
  .dashboard-side-menu-item,
  .side-menu-item {
    display: block; 
    margin-bottom: 24px; 
    text-align: center; 
  }
  
  .dashboard-small-icon {
    width: 48px; 
    height: 48px;
  }
  
  @media (max-width: 768px) {
    .dashboard-side-menu {
      position: static;        
      width: 100%;             
      height: auto;            
      flex-direction: row;     
      justify-content: center; 
      box-shadow: none;        
    }
  
    .dashboard-side-menu-item,
    .side-menu-item {
      margin: 0 16px;   
    }
  
    .dashboard-small-icon {
      width: 32px;      
      height: 32px;
    }
  }