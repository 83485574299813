.footer-container {
    color: #000000;
    background-color: #ffffff;
    text-align: center;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Syne', sans-serif;
}

.footer-link {
    color: #000000;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}