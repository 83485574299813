/* ======================
   GENERAL HEADER STYLES
   ====================== */

.header-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}
  
.header-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
    z-index: 1000;
}
  
.logo {
    font-family: 'Syne', sans-serif;
    margin: 0; 
}
  
.logo a {
    text-decoration: none;
    color: inherit;
    font-size: 1.25em;
}
  
.logo a:hover {
    color: inherit;
}
  
/* =================
     NAV & NAV LINKS
     ================= */
.bestday2marry-nav {
    display: flex;         
    flex-wrap: wrap;
    gap: 32px;
    margin-left: auto;
    font-family: 'Syne', sans-serif;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
  
.bestday2marry-nav a {
    font-family: 'Syne', sans-serif;
    color: #2B2C31;
    font-weight: bold;
    transition: color 0.5s ease;
    text-decoration: none;
    font-size: 1.25em;
}
  
.bestday2marry-nav a:hover {
    color: #E578FF;
}
  
/* ===================
     DROPDOWN (DESKTOP)
     =================== */

.dropdown {
    position: relative;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    z-index: 1000;
    padding: 8px 0;
}
  
.dropdown:hover .dropdown-content {
    display: block;
}
  
/* =====================
     HAMBURGER ICON BASE
     ===================== */

.hamburger {
    display: none;        
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 21px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: auto; 
}
  
.hamburger span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #2B2C31;
    border-radius: 2px;
    transition: all 0.3s ease;
}
  
/* OPTIONAL: X ANIMATION */

.hamburger.active span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
    opacity: 0;
}

.hamburger.active span:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
}
  
/* ===============================
     .nav-open FOR SHOWING THE NAV
     =============================== */
.nav-open {
    display: flex !important; 
}
  
/* ===========================
     MEDIA QUERY FOR MOBILE
     =========================== */

@media screen and (max-width: 768px) {
    .hamburger {
      display: flex; 
      margin-left: auto;
    }

    .logo {
        margin: 0;
    }

    .logo a {
        font-size: 1.35rem; 
    }
  
    .bestday2marry-nav {
      display: none;    
      position: absolute;
      top: 60px;        
      right: 40px;      
      background-color: #fff;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    .header-nav-container {
        padding: 10px 20px; 
      }
  
    .dropdown-content {
      position: static;  
      box-shadow: none;
      margin-top: 0;
      background-color: transparent;
    }
  
    .dropdown:hover .dropdown-content {
      display: none; 
    }
}
  