.add-venue-body {
    font-family: 'Syne', sans-serif;
    display: flex;
    height: 100vh;
    margin: 0;
    margin-bottom: 64px;
    align-items: center; 
    justify-content: center; 
}

.logo {
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: 32px;
    left: 64px;
    text-decoration: none;
}
  
.add-venue-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
  
.add-venue-container h1 {
    margin: 0 0 24px 0;
    font-size: 24px;
    color: #2B2C31;
    font-weight: bold;
}
  
.form-group {
    width: 100%;
    margin-bottom: 24px;
}
  

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1.5px solid #b5b8cb;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}
  
.submit {
    background-color: #F6D2FF;
    color: #E578FF;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    padding: 12px 64px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
}
  
.submit:hover {
    background-color: #FBECFF;
}
  
.back-button {
    background-color: #fff;
    color: #B5B8CB;
    font-size: 16px;
    border: 1.5px solid #B5B8CB;
    border-radius: 8px;
    padding: 12px 64px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
}
  
.back-button:hover {
    background-color: #F6D2FF;
    color: #E578FF;
}
  