.climformatics-logo {
    width: 40%;
    height: auto;
    margin-left: 8px;
    margin-bottom: 4px;
}

.landing-page {
    position: relative;
    width: 100%;
    text-align: center;
}

.landing-header-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    box-sizing: border-box;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black; 
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.hero-image {
    margin-top: -6%;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.hero {
    position: relative;
    margin-top: 0;
}

.hero-image-container {
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.heart-animation {
    position: absolute;
    top: 30%;
    left: 53.5%;
    width: auto;
    height: auto;
    background: transparent;
    animation: float 5s ease-in-out infinite;
    z-index: 2;
}

.heart-image {
    display: block;
    width: 9%;
    height: auto;
    animation: float 5s ease-in-out infinite;
    position: relative;
  }

@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
}

.hero-button {
    justify-items: center;
    bottom: 4%;
    z-index: 2;
    font-size: 18px;
    border-radius: 8px;
    border: none;
    color: #e578ff;
    background-color: #F6D2FF;
}

.hero-button:hover {
    background-color: #FBECFF;
    color: #F6D2FF;
}

.contact-button {
    font-size: 18px;
    border-radius: 8px;
    border: none;
    color: #e578ff;
    background-color: #F6D2FF;
}

.contact-button:hover {
    background-color: #FBECFF;
    color: #F6D2FF;
}

.header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; 
    text-align: left; 
}

.content-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; 
    padding: 64px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    gap: 70px;
}

.text-content {
    width: 130%;
    flex: 1;
    min-width: 300px;
    font-family: 'Syne', sans-serif;
}

.image-content {
    flex: 1;
    padding: 64px 0 0 64px; 
    box-sizing: border-box;
    min-width: 300px;
}

.image-content img {
    width: 100%;
    height: auto;
}

.content-title, .content-description {
    font-size: 1.35em;
    text-align: left;
    max-width: 100%;
    margin-bottom: 32px;
}

.contact-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 64px;
    background-color: #E7E8F0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    text-align: left;
    padding-bottom: 60px;
    font-family: 'Syne', sans-serif;
}

.contact-info {
    flex: 1;
    margin-right: 64px;
    min-width: 300px;
}

.contact-info h2 {
    font-size: 24px;
    font-weight: bold;
}

.contact-info p {
    font-size: 16px;
    margin-bottom: 16px;
}

.contact-info .social-icons {
    display: flex;
    gap: 16px;
}

.contact-info .social-icons a {
    font-size: 24px;
    color: #2B2C31;
    text-decoration: none;
    transition: color 0.5s ease;
}

.contact-info .social-icons a:hover {
    color: #B5B8CB;
}

.contact-form {
    padding-left: 64px;
    width: 70%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
}

.contact-form .primary-button.hero-button {
    align-self: center;
  }

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1.5px solid #B5B8CB;
    border-radius: 4px;
    box-sizing: border-box;
}

.contact-form textarea, .contact-form textarea::placeholder {
    color: #B5B8CB;
}

.contact-form input:focus, textarea:focus {
    border: 1.5px solid #E578FF; 
    box-shadow: 0 0 0 3px #F6D2FF; 
    outline: none; 
}

.contact-form textarea {
    color: #2B2C31;
}

.address-phone-info {
    margin-bottom: 32px;
}

.powered-by, #powered-by-text, .climformatics-logo {
    display:inline-block;
    vertical-align:middle;  
}

#company-address-title, #company-address, #company-phone, #company-email, #powered-by-text, .address-phone-info {
    color: #2B2C31;
}

.landing-page-footer.footer-container {
    background-color: #E7E8F0;
}

@media screen and (max-width: 1024px) {
    .header-container {
        padding: 20px 20px;
    }
    .header-text {
        left: 32px;
        right: 32px;
    }
    .contact-section, .content-section {
        padding: 32px;
    }
    .text-content {
        margin-right: 32px;
    }
    .contact-info {
        margin-right: 32px;
    }
    .contact-form {
        padding-left: 32px;
    }
}

@media screen and (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .contact-section, .content-section {
        flex-direction: column;
        padding: 32px 16px;
    }
    .text-content, .image-content, .contact-info, .contact-form {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .contact-form {
        padding-top: 32px;
    }
    .header-text {
        left: 16px;
        right: 16px;
    }
}

@media screen and (max-width: 480px) {
    .climformatics-logo {
        width: 60%;
    }

    .header-text {
        top: 16px;
        left: 16px;
        right: 16px;
    }
    .content-title, .content-description {
        font-size: 1.2em;
    }
    .contact-info h2 {
        font-size: 20px;
    }
    .contact-info p {
        font-size: 14px;
    }
    .contact-info .social-icons a {
        font-size: 20px;
    }
    .contact-section, .content-section {
        padding: 16px;
    }
}



/* text-content styles for mobile */

@media screen and (max-width: 768px) {
    .contact-button {
        position: absolute !important; 
    }
}