.tabs {
    display: flex;
    align-items: center;
    gap: 1.5%;
}

.tab-button {
    background: none;      
    border: none;          
    padding: 1%;          
    cursor: pointer;       
    outline: none;         
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.tab-button img {
    width: 50px;           
    height: 50px;         
}
  
.tab-divider {
    width: 0.1%;
    height: 80%;         
    background-color: #ccc;
}
  
.tab-button:hover {
    background-color: #f0f0f0;  
    border-radius: 4px;        
}
  
.tab-button:active {
    background-color: #e5e5e5; 
}


/******************/
/******MOBILE STYLING********/
/******************/

@media (max-width: 768px) {
    .tabs {
      flex-wrap: wrap;        
      justify-content: center; 
      gap: 8px;                
    }
}
  
.tab-button {
    background: none;
    border: none;
    padding: 1%;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.tab-button img {
    width: 50px;
    height: 50px;
}
  
@media (max-width: 768px) {
    .tab-button img {
      width: 40px;
      height: 40px;
    }
}
  
.tab-button:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
}
  
.tab-button:active {
    background-color: #e5e5e5;
}
  
.tab-divider {
    width: 0.1%;
    height: 80%;
    background-color: #ccc;
}
  
@media (max-width: 768px) {
    .tab-divider {
      display: none;
    }
}