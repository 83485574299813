.matching-flow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
  }

  main {
    margin-top: 40px;
  }
  
  main h1 {
    font-size: 28px;
    color: #2B2C31;
    margin-bottom: 16px;
    text-align: center;
  }
  
  main p {
    font-size: 16px;
    color: #2B2C31;
    text-align: center;
    margin-bottom: 24px;
  }
  
  form fieldset {
    border: none;
    padding: 0;
    margin-bottom: 24px;
  }
  
  .plan-option {
    border: 1px solid #b5b8cb;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    transition: border-color 0.3s;
  }
  
  .plan-option:hover,
  .plan-option input:focus + .plan-title {
    border-color: #E578FF;
  }
  
  .plan-option label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .plan-option input[type="radio"] {
    margin-right: 12px;
    accent-color: #E578FF;
  }
  
  .plan-title {
    font-size: 18px;
    font-weight: bold;
    color: #2B2C31;
  }
  
  .plan-details {
    font-size: 14px;
    color: #555;
    margin-top: 8px;
    padding-left: 28px;
  }
  
  .big-button,
  .small-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .big-button {
    background-color: #E578FF;
    color: #fff;
  }
  
  .big-button:hover {
    background-color: #FBECFF;
    color: #E578FF;
  }
  
  .small-button {
    background-color: #fff;
    color: #B5B8CB;
    border: 1.5px solid #B5B8CB;
  }
  
  .small-button:hover {
    background-color: #F6D2FF;
    color: #E578FF;
  }

  .visually-hidden {
    text-align: center;
  }