/* Container for the entire page */
.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Logo positioned in the top-left corner */
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #E578FF; /* Change to match your brand color */
  }
  
  /* Styling for the message block */
  .payment-message {
    text-align: center;
    margin-top: 80px;  /* Push content down so it doesn't overlap with the logo */
  }
  
  .payment-message h1 {
    font-size: 32px;
    margin-bottom: 16px;
    color: #2B2C31;
  }
  
  .payment-message p {
    font-size: 18px;
    color: #555;
  }
  
  /* Loading spinner style */
  .spinner {
    margin-top: 20px;
    width: 48px;
    height: 48px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #E578FF; /* Use your brand color here */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Keyframes for spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  