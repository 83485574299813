.logo {
    top: 48px;
    left: 64px;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
  }

.email-verification-body {
    font-family: 'Syne', sans-serif;
    display: flex;
    height: 100vh;
    margin: 0;
    margin-bottom: 64px;
    align-items: center;
    justify-content: center;
  }
  
  .email-verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    box-sizing: border-box;
  }
  
  .email-verification-form {
    font-family: 'Syne', sans-serif;
    background: #fff;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 8px; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .email-verification-form h1 {
    font-family: 'Syne', sans-serif;
    margin-bottom: 24px;
    color: #2B2C31;
    font-size: 24px;
    font-weight: bold;
  }
  
  .email-verification-form p {
    font-family: 'Syne', sans-serif;
    margin-bottom: 16px;
    color: #2B2C31;
    font-size: 16px;
  }
  
  /* The form itself */
  .email-verification-form form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box; 
    align-items: center; 
  }
  
  /* Group wrapper for inputs */
  .form-group {
    width: 100%;
    margin-bottom: 32px;
  }
  
  /* Text inputs */
  .form-group input {
    width: 100%;
    padding: 12px;
    border: 1.5px solid #b5b8cb;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Error outline for invalid input */
  .form-group input.error {
    border-color: #FF5A5F;
  }
  
  /* Error message text */
  .error-message {
    margin-top: 8px;
    font-size: 14px;
    color: #FF5A5F;
    text-align: left;
  }
  
  /* Primary "Verify" button (class="submit") */
  .submit {
    background-color: #F6D2FF;
    color: #E578FF;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    padding: 12px 64px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 16px; /* spacing before the Back button */
    box-sizing: border-box;
  }
  
  .submit:hover {
    background-color: #FBECFF;
  }
  
  /* Tertiary "Back" button */
  .tertiary-button {
    background-color: #fff;
    color: #B5B8CB;
    font-size: 16px;
    border: 1.5px solid #B5B8CB;
    border-radius: 8px;
    padding: 12px 64px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .tertiary-button:hover {
    background-color: #F6D2FF;
    color: #E578FF;
  }
  
  /* Extra container below the form for alternative/optional actions */
  .alternative-verification {
    margin-top: 16px;
    text-align: center;
  }
  
  .resend-code {
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #E578FF;
    margin-top: 8px;
  }