.date-filter-container {
    display: flex;               
    align-items: center;         
    gap: 16px;                   
    padding: 8px;     
    flex-wrap: wrap;           
}
  
.venue-name-group {
    display: flex;
    align-items: center;
    gap: 8px;                    
}
  
.venue-name-group .icon {
    width: 24px;                 
    height: 24px;
}
  
.selected-venue-text {
    font-weight: 500;
    font-size: 1rem;
    margin: 0;                 
}
  

.custom-date-group,
.date-input {
    flex: 1;        
    min-width: 180px;             
    display: flex;
    align-items: center;
}

.custom-date-group .react-datepicker-wrapper,
.date-input .react-datepicker-wrapper {
    width: 100%;
}
  
.custom-date-picker-input {
    display: flex;
    align-items: center;
    cursor: pointer;             
}
  

.date-picker-icon {
    width: 24px;                 
    height: 24px;
    margin-right: 8px;
}
  
.date-picker-text-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 8px;
    width: 100%;                
    cursor: pointer;
    outline: none;
    font-size: 0.9rem;
  }
  
.date-picker-text-input:focus {
    border-color: #7bb7fa;
}
  

.date-picker {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 0.9rem;
    box-sizing: border-box;
}
  
.date-picker:focus {
    border-color: #7bb7fa;
}

@media (max-width: 768px) {
    .date-filter-container {
      gap: 8px; 
    }
  
    .custom-date-group,
    .date-input {
      flex: 1 1 100%;
      min-width: auto;   
    }
}
  