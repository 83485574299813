
.settings-content {
    padding-left: 100px;
}

.settings-title {
    text-align: center;
}

.buttons-container {
    display: flex;
    gap: 30px;
    justify-content: center; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.toggle, .primary-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.toggle {
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
}

.primary-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .settings-page {
        display: flex;
        flex-direction: column;
    }

    .settings-content {
        padding-left: 0px;
    }
}