.solutions-page {
    display: flex;
    font-family: 'Syne', sans-serif;
    flex-direction: column;
    align-items: center;
}

.solutions-header {
    text-align: center;
    margin-bottom: 40px;
}

.solutions-title {
    font-size: 24px;
    color: #C300F1;
}

.solutions-subheading {
    font-size: 24px;
    color: #6c6c6c;
    margin-top: 20px;
}

.solutions-main {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.main-image-container {
    text-align: center;
}

.main-image {
    max-width: 100%;
    width: 800px;
}

.solutions-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    min-height: 200px;
    width: 100%;
    position: relative;
}

.toggle-options {
    display: flex;
    flex-direction: column;
    margin-right: 64px;
    min-height: 150px;
    justify-content: space-between;
}

.toggle-option {
    font-size: 21px;
    color: #6c6c6c;
    cursor: pointer;
    padding-left: 24px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border-left: 4px solid transparent;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.toggle-option.active {
    color: #2B2C31;
    border-left-color: #dcdcdc;
}

.date-range-box,
.range-filter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 30%;
    box-sizing: border-box;
}

.date-range-box img,
.range-filter-box img {
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    margin: 0;
    padding: 0;
}

.date-range-box img {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.date-range-box,
.range-filter-box {
    transition: min-height 0.3s ease, opacity 0.3s ease;
}

.business-product-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 50%;
}

.business-product-text {
    max-width: 500px;
    padding-right: 40px;
}

.business-product-text p {
    font-size: 21px;
    color: #6c6c6c;
    line-height: 1.6;
    text-align: left;
}

.feature-sneak-peek {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 50%;
}

.sneak-peek-text {
    max-width: 500px;
    padding-right: 40px;
}

.feature-title {
    color: #C300F1;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
}

.feature-subheading {
    font-size: 36px;
    color: #2B2C31;
    margin-bottom: 20px;
}

.sneak-peek-text p {
    font-size: 21px;
    color: #6c6c6c;
    line-height: 1.6;
    text-align: left;
}

.trackers-preview img {
    max-width: 500px;
    border-radius: 8px;
}



/* buttons */
.try-button {
    background-color: #f6d2ff;
    color: #e578ff;
}

.try-button:hover {
    background-color: #FBECFF;
    color: #F6D2FF;
  }


  /* mobile styles */

@media screen and (max-width: 768px) {
    .solutions-toggle {
        flex-direction: column;
    }

    .toggle-options {
        margin-right: 0px;
    }

    .range-filter-box {
        margin-top: 50px;
    }

    .business-product-section {
        max-width: 300px;
        display: flex;
        flex-direction: column;
    }

    .business-product-text {
        order: -1;
        max-width: 300px;
        margin-bottom: 50px;
    }

    .trackers-preview img {
        order: 1;
        max-width: 300px;
        border-radius: 8px;
    }

    .feature-sneak-peek {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
    }

    .sneak-peek-text {
        margin-bottom: 50px;
    }
}