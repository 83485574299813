.logo {
  top: 48px;
  left: 64px;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
}

.sign-in-body {
  font-family: 'Syne', sans-serif;
  display: flex;
  height: 100vh;
  margin: 0;
  margin-bottom: 64px;
  align-items: center; 
  justify-content: center; 
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  box-sizing: border-box;
}

.sign-in-form {
  background: #fff;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px; 
  box-sizing: border-box;
  border-radius: 8px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.sign-in-form h1 {
  margin-bottom: 24px;
  color: #2B2C31;
  font-size: 24px;
  font-weight: bold;
}

.sign-in-form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box; 
  align-items: center; 
}

.form-group {
  width: 100%;
  margin-bottom: 32px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1.5px solid #b5b8cb;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-group input.error {
  border-color: #FF5A5F;
}

.error-message {
  margin-top: 8px;
  font-size: 14px;
  color: #FF5A5F;
  text-align: left;
}

.sign-in-form .submit-button {
  background-color: #F6D2FF;
  color: #E578FF;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 12px 64px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.sign-in-form .submit-button:hover {
  background-color: #FBECFF;
}

.sign-in-form .cancel-button {
  background-color: #fff;
  color: #B5B8CB;
  font-size: 16px;
  border: 1.5px solid #B5B8CB;
  border-radius: 8px;
  padding: 12px 64px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 0;
  box-sizing: border-box;
}

.sign-in-form .cancel-button:hover {
  background-color: #F6D2FF;
  color: #E578FF;
}

#sign-up-msg {
  font-size: 14px;
  color: #2B2C31;
  text-align: center;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-link {
  font-weight: bold;
  color: #E578FF;
  text-decoration: none;
}

.sign-up-link:hover {
  text-decoration: underline;
}