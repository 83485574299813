.pricing-page-body {
  font-family: 'Syne', sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
}
  
.pricing-page {
  text-align: center;
  font-family: 'Syne', sans-serif;
}

.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; 
  text-align: left; 
}
  
  .content-above-cards {
    margin: 0 auto;
    max-width: 506px; 
  }

  
  .pricing-tagline {
    font-size: 24px;
    color: #C300F1;
  }
  
  .pricing-subheading {
    font-size: 36px;
    font-weight: bold;
  }
  
  .pricing-description {
    font-size: 24px;
    color: #6c6c6c;
  }
  
  .email-trial {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .email-trial input {
    padding: 12px 20px;
    border: 1.5px solid #b5b8cb;
    border-radius: 8px;
    font-size: 16px;
    width: 506px; 
    height: 48px;  
    margin-right: 8px;
  }
  
  
  .no-credit-info {
    font-size: 14px;
    margin-top: 16px;
  }
  
  .plans-header {
    color: #C300F1;
  }

  .plans-header h3 {
    color:#6c6c6c;
  }

  .pricing-cards-container {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    padding-bottom: 20px;
}

.pricing-cards {
    display: flex;
    justify-content: center;
    gap: 16px; 
    flex-wrap: wrap; 
    width: 100%;
}

.pricing-card {
    background-color: #fff;
    border-radius: 16px;
    padding: 64px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 334px;  
    height: auto; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 650px;
}
  
  .plan-title {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
  }

  .price {
    text-align: left;
    font-size: 36px;
    font-weight: bold;
  }
  
  .price span {
    font-size: 16px;
    color: #6c6c6c;
  }
  
  .save-info {
    color: #b1f56b;
    font-size: 14px;
  }
  
  .access-type {
    font-size: 16px;
  }
  
  .features-list {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 20px;
    text-align: left;
  }
  
  .features-list li {
    font-size: 18px;
    color: #6c6c6c;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  
  .features-list li img {
    margin-right: 8px;
  }
  
  .cta-button {
    background-color: #f6d2ff;
    color: #e578ff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 32px;
  }
  
  .cta-button:hover, .trial-button:hover {
    background-color: #FBECFF;
    color: #F6D2FF;
  }
  
  .card-divider {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  .custom-card-divider {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 32px;
  }

  .features-list li img {
    margin-right: 8px;
    width: 24px; 
    height: 24px; 
  }
  

/* buttons */

.trial-button {
  font-size: 18px;
  border-radius: 8px;
  border: none;
  color: #e578ff;
  background-color: #F6D2FF;
}

.trial-button:hover {
  background-color: #FBECFF;
  color: #F6D2FF;
}


/* mobile styling */

@media screen and (max-width: 768px) {
  .content-above-cards {
    padding: 2rem;
  }

  .pricing-cards-container {
    padding: 2rem;
  }
}