/* Container for the entire tags matching page */
.tags-matching-container {
    font-family: 'Raleway', sans-serif;
    padding: 20px;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
  }
  
  .tags-matching-header {
    text-align: center;
    margin-top: 80px; 
    margin-bottom: 20px;
  }
  
  .tags-matching-header h1 {
    font-size: 28px;
    color: #2B2C31;
    margin-bottom: 10px;
  }
  
  .tags-matching-header p {
    font-size: 16px;
    color: #555;
  }
  
  .custom-tags-selector {
    position: relative;
    width: 100%;
    margin: 20px auto;
  }
  
  .selected-tags-field {
    border: 1.5px solid #B5B8CB;
    border-radius: 8px;
    padding: 12px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #2B2C31;
  }
  
  .selected-tags-field:hover {
    border-color: #E578FF;
  }
  
  .dropdown-arrow {
    margin-left: 10px;
  }
  
  .options-container {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1.5px solid #B5B8CB;
    border-radius: 8px;
    background-color: #fff;
    z-index: 100;
  }
  
  .option-item {
    padding: 12px;
    cursor: pointer;
    font-size: 16px;
    color: #2B2C31;
  }
  
  .option-item:hover {
    background-color: #FBECFF;
  }
  
  .option-selected {
    background-color: #E578FF;
    color: #fff;
  }
  
  .continue-button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #E578FF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .continue-button:hover {
    background-color: #FBECFF;
    color: #E578FF;
  }
  

  .venues-bucket {
    display: flex;
    overflow-x: auto;
    gap: .5rem;
    padding: .5rem;
    align-self: center;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
  
  .venue-card {
    flex: 0 0 80%;     
    max-width: 400px;     
    max-height: 400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
  }

  .venue-card.selected {
    border: 4px solid #E578FF;
  }
  
  .venue-image {
    height: 200px;
    object-fit: cover;
  }
  
  .venue-card .card-content {
    flex: 1;
    padding: 1rem;
    overflow: hidden;
  }

  .proceed-button {
    display: block;
    font-size: 16px;
    color: #fff;
    background-color: #E578FF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .proceed-button:hover {
    background-color: #FBECFF;
    color: #E578FF;
  }
  
  @media (min-width: 768px) {
    .venues-bucket {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      padding: 2rem;
      overflow-x: visible; 
    }

    .venue-card {
      scroll-snap-align: none;
    }
  }
  
  @media (min-width: 1280px) {
    .venues-bucket {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (min-width: 1920px) {
    .venues-bucket {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 2rem;
      padding: 2rem;
      width: fit-content;
      margin: 0 auto;      
    }
  }